// Blueprint SCSS Variables

// Blueprint SCSS Mixins

@use '../../../../../../../node_modules/@groma-dev/blueprint/lib/assets/sass/abstracts/breakpoints' as *;

//// Mixin Helpers
@mixin fontFamily($variant) {
  @if $variant == 'primary' {
    font-family: Good Sans, Helvetica, sans-serif;
  } @else {
    font-family: IBM Plex, Helvetica, sans-serif;
  }
}

@mixin baseFontStyles() {
  color: var(--content100, #293831);
  font-style: normal;
  margin: 0;
  text-decoration: none;
  text-transform: none;
}

@mixin fontWeight($weight) {
  @if $weight == 'bold' {
    font-weight: 700;
  } @else if $weight == 'light' {
    font-weight: 100;
  } @else {
    font-weight: 400;
  }
}

//// Mixins

// Primary Text Mixin
// Variants are X50, X25 and X00 where X can be 0 - 7.
@mixin primaryText($variant) {
  @include fontFamily('primary');
  @include baseFontStyles();
  @if $variant == 750 {
    @include fontWeight('bold');
  } @else if $variant == 725 {
    @include fontWeight('normal');

    font-size: 92px;
    line-height: 114px;
  } @else if $variant == 700 {
    @include fontWeight('light');

    font-size: 92px;
    line-height: 114px;
  } @else if $variant == 650 {
    @include fontWeight('bold');

    font-size: 52px;
    line-height: 64px;
  } @else if $variant == 625 {
    @include fontWeight('normal');

    font-size: 52px;
    line-height: 64px;
  } @else if $variant == 600 {
    @include fontWeight('light');

    font-size: 52px;
    line-height: 64px;
  } @else if $variant == 550 {
    @include fontWeight('bold');

    font-size: 36px;
    line-height: 46px;
  } @else if $variant == 525 {
    @include fontWeight('normal');

    font-size: 36px;
    line-height: 46px;
  } @else if $variant == 500 {
    @include fontWeight('light');

    font-size: 36px;
    line-height: 46px;
  } @else if $variant == 450 {
    @include fontWeight('bold');

    font-size: 28px;
    line-height: 34px;
  } @else if $variant == 425 {
    @include fontWeight('normal');

    font-size: 28px;
    line-height: 34px;
  } @else if $variant == 400 {
    @include fontWeight('light');

    font-size: 28px;
    line-height: 34px;
  } @else if $variant == 350 {
    @include fontWeight('bold');

    font-size: 22px;
    line-height: 32px;
  } @else if $variant == 325 {
    @include fontWeight('normal');

    font-size: 22px;
    line-height: 26px;
  } @else if $variant == 300 {
    @include fontWeight('light');

    font-size: 22px;
    line-height: 32px;
  } @else if $variant == 250 {
    @include fontWeight('bold');

    font-size: 18px;
    line-height: 28px;
  } @else if $variant == 225 {
    @include fontWeight('normal');

    font-size: 18px;
    line-height: 28px;
  } @else if $variant == 200 {
    @include fontWeight('light');

    font-size: 18px;
    line-height: 28px;
  } @else if $variant == 150 {
    @include fontWeight('bold');

    font-size: 14px;
    line-height: 17px;
  } @else if $variant == 125 {
    @include fontWeight('normal');

    font-size: 14px;
    line-height: 24px;
  } @else if $variant == 100 {
    @include fontWeight('light');

    font-size: 14px;
    line-height: 24px;
  } @else if $variant == 050 {
    @include fontWeight('bold');

    font-size: 12px;
    line-height: 14px;
  } @else if $variant == 025 {
    @include fontWeight('normal');

    font-size: 12px;
    line-height: 16px;
  } @else if $variant == 000 {
    @include fontWeight('light');

    font-size: 12px;
    line-height: 16px;
  }
}

// Secondary Text mixin
// Variants are 200, 100, and 000
@mixin secondaryText($variant) {
  @include fontFamily('secondary');
  @include baseFontStyles();
  @if $variant == 200 {
    @include fontWeight('normal');

    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
  } @else if $variant == 100 {
    @include fontWeight('normal');

    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
  } @else if $variant == 000 {
    @include fontWeight('normal');

    font-size: 10px;
    line-height: 13px;
  }
}

// Sub Text mixin
// variants are 150, 125, 100, 050, 025 and 000
@mixin subText($variant) {
  @include fontFamily('primary');
  @include baseFontStyles();

  @if $variant == 150 {
    @include fontWeight('light');

    font-size: 10px;
    line-height: 12px;
  } @else if $variant == 125 {
    @include fontWeight('normal');

    font-size: 10px;
    line-height: 12px;
  } @else if $variant == 100 {
    @include fontWeight('light');

    font-size: 10px;
    line-height: 12px;
  } @else if $variant == 050 {
    @include fontWeight('bold');

    font-size: 8px;
    line-height: 10px;
  } @else if $variant == 025 {
    @include fontWeight('normal');

    font-size: 8px;
    line-height: 10px;
  } @else if $variant == 000 {
    @include fontWeight('light');

    font-size: 8px;
    line-height: 10px;
  }
}

// Flex Mixin
// returns flex styles needed
// defaults to row, normal for both justify-content and align-items
@mixin flexMixin($direction: row, $justifyContent: normal, $alignItems: normal) {
  align-items: $alignItems;
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
}

// Z-Index mixin
// Defaults to 1 when no index is passed
@mixin zIndexMixin($zIndex: 1) {
  z-index: $zIndex;
}

// DropShadow mixin
// pass in variant of either 100 or 200
@mixin shadowMixin($variant) {
  @if $variant == 100 {
    -moz-box-shadow: var(--shadow100);
    -webkit-box-shadow: var(--shadow100);
    box-shadow: var(--shadow100);
  } @else if $variant == 200 {
    -moz-box-shadow: var(--shadow200);
    -webkit-box-shadow: var(--shadow200);
    box-shadow: var(--shadow200);
  }
}

/// Responsive breakpoint manager
/// @access publicHome
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

// Blueprint SCSS Functions

@function vh($quantity: 100) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}




.section {
  color: var(--neutral100);
  padding: var(--s4) var(--s6) var(--s3);
  width: 100%;

  :global(.Header-menuSection-heading) {
    color: var(--neutral300);
    width: 100%;
    text-align: left;

    @include respond-to(md) {
      @include secondaryText(100);
      border-top: 1px solid var(--primary200);
      border-bottom: 1px solid var(--primary200);
      color: var(--neutral300);
      padding: var(--s4) var(--s3);
    }
  }

  &:not(:last-child) {
    border-right: 1px solid var(--primary200);
  }

  @include respond-to(md) {
    height: fit-content;
    padding: 0;
  }
}

.sectionWrapper {
  @include flexMixin(column, space-between);
  height: 100%;
  width: 100%;
}

.accountContainer {
  @include flexMixin(column);
  height: 100%;
}

.menuItem {
  @include flexMixin(row, space-between, center);
  @include primaryText(150);
  border-top: 1px solid var(--primary300);
  color: var(--neutral100);
  height: 80px;
  min-width: 100%;
  padding: var(--s5) var(--s3);

  &:last-child {
    border-bottom: 1px solid var(--primary300);
  }

  svg {
    width: 16px;
    path {
      fill: var(--neutral100);
    }
  }

  &.account {
    border-top: 1px solid var(--primary200);
    border-bottom: 1px solid var(--primary200);
  }

  &.submenu {
    @include primaryText(100);
    color: var(--neutral100);
    height: fit-content;
    margin-top: var(--s2);
    padding: var(--s3);
  }

  &.link {
    @include primaryText(150);
    color: var(--neutral100);
    border-top: 1px solid var(--primary200);

    &:last-child {
      border-bottom: 1px solid var(--primary200);
    }
  }
}

.primaryLink {
  @include flexMixin(column, flex-start, flex-start);
  @include primaryText(000);
  color: var(--neutral300);
  margin-bottom: var(--s4);
  position: relative;
  text-align: left;
  width: 100%;

  span {
    @include primaryText(150);
    color: var(--neutral100);
    margin-bottom: var(--s2);
  }

  &.active {
    &:after {
      background-color: var(--secondary300);
      content: ' ';
      height: 100%;
      left: -8px;
      position: absolute;
      width: 2px;
    }
  }

  &:hover,
  &:focus {
    &:after {
      background-color: var(--secondary300);
      content: ' ';
      height: 100%;
      left: -8px;
      position: absolute;
      width: 2px;
    }
  }
}

.secondaryLink {
  @include flexMixin(column, flex-start, flex-start);
  @include primaryText(000);
  color: var(--neutral100);
  margin-bottom: var(--s4);
  text-align: left;
  width: 100%;

  &:hover,
  &:focus {
    color: var(--secondary300);
  }

  &.active {
    color: var(--secondary300);
  }
}

.ctaContainer {
  background-color: var(--primary400);
  width: 100%;

  @include respond-to(md) {
    justify-content: flex-end;
    background-color: transparent;
    margin: var(--s4) auto 0;
    height: 100%;
  }

  @include respond-to(sm) {
    width: calc(100vw - var(--s4) - var(--s4));
  }
}

.ctaWrapper {
  @include flexMixin(column, flex-start);
  width: 100%;
  height: 100%;

  @include respond-to(md) {
    @include flexMixin(row, flex-start, flex-start);
    height: fit-content;
    background-color: var(--primary400);
    margin: var(--s4) auto 0;
    padding: var(--s5);
  }

  @include respond-to(sm) {
    @include flexMixin(column, flex-start);
  }
}

.ctaAsset {
  width: 204px;
  @include respond-to(sm) {
    width: 100%;
  }
}

.ctaContentContainer {
  @include flexMixin(column, flex-start);

  @include respond-to(md) {
    margin-left: var(--s3);
    max-width: 325px;
  }

  @include respond-to(sm) {
    margin-left: 0;
  }
}

.ctaContent {
  @include primaryText(150);
  color: var(--neutral100);
  text-align: left;
  margin: var(--s4) 0;
}

.cta {
  @include flexMixin(row, space-between, center);
  @include secondaryText(100);
  width: fit-content;
  color: var(--secondary300);
  text-decoration: underline;

  svg {
    width: 16px;
    height: 16px;
    margin-left: var(--s2);
    path {
      fill: var(--secondary300);
    }
  }
}

.wrapper {
  @include flexMixin(column);

  @include respond-to(md) {
    padding: var(--s0) var(--s4);
  }
}

.sectionUtil {
  @include flexMixin(column, flex-start, flex-start);
  @include primaryText(050);
  align-self: flex-end;
  border-radius: 2px;
  border: 1px solid var(--primary200);
  color: var(--neutral100);
  justify-self: flex-end;
  padding: var(--s3);
  width: 100%;

  .email {
    @include primaryText(000);
    color: var(--secondary300);
    text-decoration: underline;
    margin: var(--s2) var(--s0) var(--s1);
  }

  .phone {
    @include primaryText(000);
    color: var(--neural100);
  }

  @include respond-to(md) {
    margin: var(--s6) auto 0;
    width: calc(100vw - var(--s4) - var(--s4));
  }
}
