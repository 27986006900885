// Blueprint SCSS Variables

// Blueprint SCSS Mixins

@use '../../../../../../../node_modules/@groma-dev/blueprint/lib/assets/sass/abstracts/breakpoints' as *;

//// Mixin Helpers
@mixin fontFamily($variant) {
  @if $variant == 'primary' {
    font-family: Good Sans, Helvetica, sans-serif;
  } @else {
    font-family: IBM Plex, Helvetica, sans-serif;
  }
}

@mixin baseFontStyles() {
  color: var(--content100, #293831);
  font-style: normal;
  margin: 0;
  text-decoration: none;
  text-transform: none;
}

@mixin fontWeight($weight) {
  @if $weight == 'bold' {
    font-weight: 700;
  } @else if $weight == 'light' {
    font-weight: 100;
  } @else {
    font-weight: 400;
  }
}

//// Mixins

// Primary Text Mixin
// Variants are X50, X25 and X00 where X can be 0 - 7.
@mixin primaryText($variant) {
  @include fontFamily('primary');
  @include baseFontStyles();
  @if $variant == 750 {
    @include fontWeight('bold');
  } @else if $variant == 725 {
    @include fontWeight('normal');

    font-size: 92px;
    line-height: 114px;
  } @else if $variant == 700 {
    @include fontWeight('light');

    font-size: 92px;
    line-height: 114px;
  } @else if $variant == 650 {
    @include fontWeight('bold');

    font-size: 52px;
    line-height: 64px;
  } @else if $variant == 625 {
    @include fontWeight('normal');

    font-size: 52px;
    line-height: 64px;
  } @else if $variant == 600 {
    @include fontWeight('light');

    font-size: 52px;
    line-height: 64px;
  } @else if $variant == 550 {
    @include fontWeight('bold');

    font-size: 36px;
    line-height: 46px;
  } @else if $variant == 525 {
    @include fontWeight('normal');

    font-size: 36px;
    line-height: 46px;
  } @else if $variant == 500 {
    @include fontWeight('light');

    font-size: 36px;
    line-height: 46px;
  } @else if $variant == 450 {
    @include fontWeight('bold');

    font-size: 28px;
    line-height: 34px;
  } @else if $variant == 425 {
    @include fontWeight('normal');

    font-size: 28px;
    line-height: 34px;
  } @else if $variant == 400 {
    @include fontWeight('light');

    font-size: 28px;
    line-height: 34px;
  } @else if $variant == 350 {
    @include fontWeight('bold');

    font-size: 22px;
    line-height: 32px;
  } @else if $variant == 325 {
    @include fontWeight('normal');

    font-size: 22px;
    line-height: 26px;
  } @else if $variant == 300 {
    @include fontWeight('light');

    font-size: 22px;
    line-height: 32px;
  } @else if $variant == 250 {
    @include fontWeight('bold');

    font-size: 18px;
    line-height: 28px;
  } @else if $variant == 225 {
    @include fontWeight('normal');

    font-size: 18px;
    line-height: 28px;
  } @else if $variant == 200 {
    @include fontWeight('light');

    font-size: 18px;
    line-height: 28px;
  } @else if $variant == 150 {
    @include fontWeight('bold');

    font-size: 14px;
    line-height: 17px;
  } @else if $variant == 125 {
    @include fontWeight('normal');

    font-size: 14px;
    line-height: 24px;
  } @else if $variant == 100 {
    @include fontWeight('light');

    font-size: 14px;
    line-height: 24px;
  } @else if $variant == 050 {
    @include fontWeight('bold');

    font-size: 12px;
    line-height: 14px;
  } @else if $variant == 025 {
    @include fontWeight('normal');

    font-size: 12px;
    line-height: 16px;
  } @else if $variant == 000 {
    @include fontWeight('light');

    font-size: 12px;
    line-height: 16px;
  }
}

// Secondary Text mixin
// Variants are 200, 100, and 000
@mixin secondaryText($variant) {
  @include fontFamily('secondary');
  @include baseFontStyles();
  @if $variant == 200 {
    @include fontWeight('normal');

    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
  } @else if $variant == 100 {
    @include fontWeight('normal');

    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
  } @else if $variant == 000 {
    @include fontWeight('normal');

    font-size: 10px;
    line-height: 13px;
  }
}

// Sub Text mixin
// variants are 150, 125, 100, 050, 025 and 000
@mixin subText($variant) {
  @include fontFamily('primary');
  @include baseFontStyles();

  @if $variant == 150 {
    @include fontWeight('light');

    font-size: 10px;
    line-height: 12px;
  } @else if $variant == 125 {
    @include fontWeight('normal');

    font-size: 10px;
    line-height: 12px;
  } @else if $variant == 100 {
    @include fontWeight('light');

    font-size: 10px;
    line-height: 12px;
  } @else if $variant == 050 {
    @include fontWeight('bold');

    font-size: 8px;
    line-height: 10px;
  } @else if $variant == 025 {
    @include fontWeight('normal');

    font-size: 8px;
    line-height: 10px;
  } @else if $variant == 000 {
    @include fontWeight('light');

    font-size: 8px;
    line-height: 10px;
  }
}

// Flex Mixin
// returns flex styles needed
// defaults to row, normal for both justify-content and align-items
@mixin flexMixin($direction: row, $justifyContent: normal, $alignItems: normal) {
  align-items: $alignItems;
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
}

// Z-Index mixin
// Defaults to 1 when no index is passed
@mixin zIndexMixin($zIndex: 1) {
  z-index: $zIndex;
}

// DropShadow mixin
// pass in variant of either 100 or 200
@mixin shadowMixin($variant) {
  @if $variant == 100 {
    -moz-box-shadow: var(--shadow100);
    -webkit-box-shadow: var(--shadow100);
    box-shadow: var(--shadow100);
  } @else if $variant == 200 {
    -moz-box-shadow: var(--shadow200);
    -webkit-box-shadow: var(--shadow200);
    box-shadow: var(--shadow200);
  }
}

/// Responsive breakpoint manager
/// @access publicHome
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

// Blueprint SCSS Functions

@function vh($quantity: 100) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}




.menuContainer {
  @include flexMixin(column);
  background-color: var(--primary400);
  color: var(--neutral100);
  height: calc(#{vh()} - var(--headerHeight));
  overflow: hidden;
  position: fixed;
  top: var(--headerHeight);
  transform: translateX(100%);
  transition: transform 0.25s ease-out, visibility 0.25s;
  visibility: hidden;
  width: 100vw;

  &.open {
    transform: translateX(0);
    visibility: visible;
  }

  &.hidden {
    visibility: hidden;
  }
}

.menuList {
  @include flexMixin(column);
  height: 100%;
  min-width: 100%;
  overflow: auto;
  width: 100%;
}

.submenuContainer {
  @include flexMixin(column);
  background-color: var(--primary300);
  color: var(--neutral100);
  height: 0;
  overflow: auto;
  position: fixed;
  top: var(--headerHeight);
  transition: height 0.25s ease-out, visibility 0.25s;
  visibility: hidden;
  width: 100vw;

  &.open {
    height: calc(#{vh()} - var(--headerHeight));
    visibility: visible;
  }
}

.list {
  @include primaryText(100);

  color: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    text-align: start;
    width: inherit;
  }
}

.listItem {
  cursor: pointer;
  line-height: 31.5px;
  display: inline-flex;
  height: 100%;
  width: inherit;

  &:hover {
    color: var(--secondary300);
  }
}

.menuItem {
  @include flexMixin(row, space-between, center);
  @include primaryText(150);
  border-top: 1px solid var(--primary300);
  color: var(--neutral100);
  height: 80px;
  min-width: 100%;
  padding: var(--s5) var(--s3);

  &:last-child {
    border-bottom: 1px solid var(--primary300);
  }

  svg {
    width: 16px;
    path {
      fill: var(--neutral100);
    }
  }

  &.account {
    border-top: 1px solid var(--primary200);
    border-bottom: 1px solid var(--primary200);
  }

  &.submenu {
    @include primaryText(100);
    height: fit-content;
    margin-top: var(--s2);
    padding: var(--s3);
  }
}

.profileContent {
  @include flexMixin(row, flex-start, center);
  @include primaryText(150);
  gap: var(--s3);
}

.signInLink {
  padding: var(--s5) var(--s4);
  &:global(.Link-local) {
    @include flexMixin(row, flex-start, center);
    width: 100%;
    a {
      width: 100%;
      @include flexMixin(row, flex-start, center);
    }
  }

  svg {
    height: 16px;
    margin-left: var(--s2);
    path {
      fill: var(--secondary300);
    }
  }
}

.accountContainer {
  @include flexMixin(column);
  height: 100%;
}

.section {
  @include flexMixin(column);
  :global(.Header-menuSection-heading) {
    @include secondaryText(100);
    border-top: 1px solid var(--primary200);
    border-bottom: 1px solid var(--primary200);
    color: var(--neutral300);
    padding: var(--s4) var(--s3);
  }
}
