// Blueprint SCSS Variables

// Blueprint SCSS Mixins

@use '../../../../../node_modules/@groma-dev/blueprint/lib/assets/sass/abstracts/breakpoints' as *;

//// Mixin Helpers
@mixin fontFamily($variant) {
  @if $variant == 'primary' {
    font-family: Good Sans, Helvetica, sans-serif;
  } @else {
    font-family: IBM Plex, Helvetica, sans-serif;
  }
}

@mixin baseFontStyles() {
  color: var(--content100, #293831);
  font-style: normal;
  margin: 0;
  text-decoration: none;
  text-transform: none;
}

@mixin fontWeight($weight) {
  @if $weight == 'bold' {
    font-weight: 700;
  } @else if $weight == 'light' {
    font-weight: 100;
  } @else {
    font-weight: 400;
  }
}

//// Mixins

// Primary Text Mixin
// Variants are X50, X25 and X00 where X can be 0 - 7.
@mixin primaryText($variant) {
  @include fontFamily('primary');
  @include baseFontStyles();
  @if $variant == 750 {
    @include fontWeight('bold');
  } @else if $variant == 725 {
    @include fontWeight('normal');

    font-size: 92px;
    line-height: 114px;
  } @else if $variant == 700 {
    @include fontWeight('light');

    font-size: 92px;
    line-height: 114px;
  } @else if $variant == 650 {
    @include fontWeight('bold');

    font-size: 52px;
    line-height: 64px;
  } @else if $variant == 625 {
    @include fontWeight('normal');

    font-size: 52px;
    line-height: 64px;
  } @else if $variant == 600 {
    @include fontWeight('light');

    font-size: 52px;
    line-height: 64px;
  } @else if $variant == 550 {
    @include fontWeight('bold');

    font-size: 36px;
    line-height: 46px;
  } @else if $variant == 525 {
    @include fontWeight('normal');

    font-size: 36px;
    line-height: 46px;
  } @else if $variant == 500 {
    @include fontWeight('light');

    font-size: 36px;
    line-height: 46px;
  } @else if $variant == 450 {
    @include fontWeight('bold');

    font-size: 28px;
    line-height: 34px;
  } @else if $variant == 425 {
    @include fontWeight('normal');

    font-size: 28px;
    line-height: 34px;
  } @else if $variant == 400 {
    @include fontWeight('light');

    font-size: 28px;
    line-height: 34px;
  } @else if $variant == 350 {
    @include fontWeight('bold');

    font-size: 22px;
    line-height: 32px;
  } @else if $variant == 325 {
    @include fontWeight('normal');

    font-size: 22px;
    line-height: 26px;
  } @else if $variant == 300 {
    @include fontWeight('light');

    font-size: 22px;
    line-height: 32px;
  } @else if $variant == 250 {
    @include fontWeight('bold');

    font-size: 18px;
    line-height: 28px;
  } @else if $variant == 225 {
    @include fontWeight('normal');

    font-size: 18px;
    line-height: 28px;
  } @else if $variant == 200 {
    @include fontWeight('light');

    font-size: 18px;
    line-height: 28px;
  } @else if $variant == 150 {
    @include fontWeight('bold');

    font-size: 14px;
    line-height: 17px;
  } @else if $variant == 125 {
    @include fontWeight('normal');

    font-size: 14px;
    line-height: 24px;
  } @else if $variant == 100 {
    @include fontWeight('light');

    font-size: 14px;
    line-height: 24px;
  } @else if $variant == 050 {
    @include fontWeight('bold');

    font-size: 12px;
    line-height: 14px;
  } @else if $variant == 025 {
    @include fontWeight('normal');

    font-size: 12px;
    line-height: 16px;
  } @else if $variant == 000 {
    @include fontWeight('light');

    font-size: 12px;
    line-height: 16px;
  }
}

// Secondary Text mixin
// Variants are 200, 100, and 000
@mixin secondaryText($variant) {
  @include fontFamily('secondary');
  @include baseFontStyles();
  @if $variant == 200 {
    @include fontWeight('normal');

    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
  } @else if $variant == 100 {
    @include fontWeight('normal');

    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
  } @else if $variant == 000 {
    @include fontWeight('normal');

    font-size: 10px;
    line-height: 13px;
  }
}

// Sub Text mixin
// variants are 150, 125, 100, 050, 025 and 000
@mixin subText($variant) {
  @include fontFamily('primary');
  @include baseFontStyles();

  @if $variant == 150 {
    @include fontWeight('light');

    font-size: 10px;
    line-height: 12px;
  } @else if $variant == 125 {
    @include fontWeight('normal');

    font-size: 10px;
    line-height: 12px;
  } @else if $variant == 100 {
    @include fontWeight('light');

    font-size: 10px;
    line-height: 12px;
  } @else if $variant == 050 {
    @include fontWeight('bold');

    font-size: 8px;
    line-height: 10px;
  } @else if $variant == 025 {
    @include fontWeight('normal');

    font-size: 8px;
    line-height: 10px;
  } @else if $variant == 000 {
    @include fontWeight('light');

    font-size: 8px;
    line-height: 10px;
  }
}

// Flex Mixin
// returns flex styles needed
// defaults to row, normal for both justify-content and align-items
@mixin flexMixin($direction: row, $justifyContent: normal, $alignItems: normal) {
  align-items: $alignItems;
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
}

// Z-Index mixin
// Defaults to 1 when no index is passed
@mixin zIndexMixin($zIndex: 1) {
  z-index: $zIndex;
}

// DropShadow mixin
// pass in variant of either 100 or 200
@mixin shadowMixin($variant) {
  @if $variant == 100 {
    -moz-box-shadow: var(--shadow100);
    -webkit-box-shadow: var(--shadow100);
    box-shadow: var(--shadow100);
  } @else if $variant == 200 {
    -moz-box-shadow: var(--shadow200);
    -webkit-box-shadow: var(--shadow200);
    box-shadow: var(--shadow200);
  }
}

/// Responsive breakpoint manager
/// @access publicHome
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(type-of($raw-query) == 'string', unquote($raw-query), inspect($raw-query));

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

// Blueprint SCSS Functions

@function vh($quantity: 100) {
  @return calc(var(--vh, 1vh) * #{$quantity});
}




.menuSection {
  @include flexMixin(column, normal, baseline);
  gap: var(--s4);
}

.sectionHeading {
  @include secondaryText(000);
  color: inherit;
  text-transform: uppercase;
}
